import { ComponentPropsWithoutRef } from 'react';

export type FormControlProps = ComponentPropsWithoutRef<'div'>;

/** A container component for a collection of a form input, label, and hint
 * text that are to make up a single form entry */
export function FormControl({
  className = '',
  children,
  ...props
}: FormControlProps) {
  const styles = 'flex flex-col gap-2';
  return (
    <div className={`${styles} ${className}`} {...props}>
      {children}
    </div>
  );
}
