import { ComponentPropsWithoutRef } from 'react';

import { ReactComponent as HamburgerMenu } from './svgs/hamburger-menu.svg';
import { ReactComponent as Checkmark } from './svgs/checkmark.svg';
import { ReactComponent as InfoCircle } from './svgs/info-circle.svg';
import { ReactComponent as List } from './svgs/list.svg';
import { ReactComponent as Folder } from './svgs/folder.svg';
import { ReactComponent as GradCap } from './svgs/grad-cap.svg';
import { ReactComponent as Compass } from './svgs/compass.svg';
import { ReactComponent as QuestionCircle } from './svgs/question-circle.svg';
import { ReactComponent as Person } from './svgs/person.svg';
import { ReactComponent as Envelope } from './svgs/envelope.svg';
import { ReactComponent as LeftArrow } from './svgs/left-arrow.svg';
import { ReactComponent as Close } from './svgs/close.svg';
import { ReactComponent as Pencil } from './svgs/pencil.svg';
import { ReactComponent as Upload } from './svgs/upload.svg';
import { ReactComponent as FileIcon } from './svgs/file.svg';
import { ReactComponent as XCircle } from './svgs/x-circle.svg';
import { ReactComponent as CheckmarkCircle } from './svgs/checkmark-circle.svg';
import { ReactComponent as ChevronDown } from './svgs/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './svgs/chevron-left.svg';
import { ReactComponent as ChevronRight } from './svgs/chevron-right.svg';
import { ReactComponent as ChevronUp } from './svgs/chevron-up.svg';
import { ReactComponent as DownloadDocuments } from './svgs/download-documents.svg';
import { ReactComponent as Download } from './svgs/download.svg';
import { ReactComponent as Bulb } from './svgs/bulb.svg';
import { ReactComponent as Home } from './svgs/home.svg';
import { ReactComponent as Exit } from './svgs/exit.svg';
import { ReactComponent as File2 } from './svgs/file-2.svg';
import { ReactComponent as Comment } from './svgs/comment.svg';
import { ReactComponent as Overview } from './svgs/overview.svg';
import { ReactComponent as Mail } from './svgs/mail.svg';
import { ReactComponent as ClientStatus } from './svgs/client-status.svg';
import { ReactComponent as Delete } from './svgs/delete.svg';
import { ReactComponent as OutlinedPencil } from './svgs/outlined-pencil.svg';
import { ReactComponent as OutlinedExit } from './svgs/outlined-exit.svg';
import { ReactComponent as OutlinedFiles } from './svgs/outlined-files.svg';
import { ReactComponent as Avatar } from './svgs/avatar.svg';
import { ReactComponent as AmyHeadshot } from './svgs/amy-headshot.svg';
import { ReactComponent as Phone } from './svgs/phone.svg';
import { ReactComponent as OutlinedFolder } from './svgs/outlined-folder.svg';
import { ReactComponent as Comment2 } from './svgs/comment-2.svg';
import { ReactComponent as Star } from './svgs/star.svg';
import { ReactComponent as Speaker } from './svgs/speaker.svg';
import { ReactComponent as OrangeCircleArrow } from './svgs/orange-circle-arrow.svg';
import { ReactComponent as Flag } from './svgs/flag.svg';
import { ReactComponent as ExclamationCircle } from './svgs/exclamation-circle.svg';
import { ReactComponent as HappyPeople } from './svgs/happy-people.svg';
import { ReactComponent as HomeSearchImage } from './svgs/home-search-image.svg';
import { ReactComponent as MakeAnOffer } from './svgs/make-an-offer.svg';
import { ReactComponent as CalendarWithPeople } from './svgs/calender-with-people.svg';
import { ReactComponent as MoveIn } from './svgs/move-in.svg';
import { ReactComponent as PrepareForConditionsImage } from './svgs/prep-for-conditions-image.svg';
import { ReactComponent as FulfillConditionsImage } from './svgs/fulfill-conditions-image.svg';
import { ReactComponent as ThankYou } from './svgs/thank-you.svg';
import { ReactComponent as InviteClients } from './svgs/invite-clients.svg';
import { ReactComponent as GiftBox } from './svgs/gift-box.svg';
import { ReactComponent as FilterImage } from './svgs/filter.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as OutlinedHeart } from './svgs/outlined-heart.svg';
import { ReactComponent as Heart } from './svgs/heart.svg';
import { ReactComponent as Facebook } from './svgs/facebook.svg';
import { ReactComponent as FbMessenger } from './svgs/fb-messenger.svg';
import { ReactComponent as Instagram } from './svgs/instagram.svg';
import { ReactComponent as WhatsApp } from './svgs/whatsapp.svg';
import { ReactComponent as Reddit } from './svgs/reddit.svg';
import { ReactComponent as Share } from './svgs/share.svg';

interface BaseProps {
  glyph: IconOptions;
}

export type IconProps = BaseProps &
  Omit<ComponentPropsWithoutRef<'svg'>, keyof BaseProps>;

export function Icon({ glyph, className = '', ...props }: IconProps) {
  const Component = iconMap[glyph];
  return <Component className={`inline-block ${className}`} {...props} />;
}

type SVGComponent = typeof HamburgerMenu;
type IconOptions =
  | 'hamburgerMenu'
  | 'checkmark'
  | 'infoCircle'
  | 'list'
  | 'folder'
  | 'gradCap'
  | 'compass'
  | 'questionCircle'
  | 'person'
  | 'envelope'
  | 'leftArrow'
  | 'close'
  | 'pencil'
  | 'upload'
  | 'file'
  | 'xCircle'
  | 'checkmarkCircle'
  | 'chevronDown'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'downloadDocuments'
  | 'home'
  | 'bulb'
  | 'exit'
  | 'file-2'
  | 'comment'
  | 'download'
  | 'overview'
  | 'mail'
  | 'clientStatus'
  | 'delete'
  | 'outlinedPencil'
  | 'outlinedExit'
  | 'outlinedFiles'
  | 'avatar'
  | 'amyHeadshot'
  | 'phone'
  | 'outlinedFolder'
  | 'comment-2'
  | 'star'
  | 'speaker'
  | 'orangeCircleArrow'
  | 'flag'
  | 'exclamationCircle'
  | 'happyPeople'
  | 'homeSearchImage'
  | 'makeAnOffer'
  | 'calendarWithPeople'
  | 'moveIn'
  | 'prepareForConditionsImage'
  | 'fulfillConditionsImage'
  | 'thankYou'
  | 'inviteClients'
  | 'giftBox'
  | 'filter'
  | 'search'
  | 'outlinedHeart'
  | 'heart'
  | 'facebook'
  | 'fb-messenger'
  | 'instagram'
  | 'whatsapp'
  | 'reddit'
  | 'share';

const iconMap: Record<IconOptions, SVGComponent> = {
  hamburgerMenu: HamburgerMenu,
  checkmark: Checkmark,
  infoCircle: InfoCircle,
  list: List,
  folder: Folder,
  gradCap: GradCap,
  compass: Compass,
  questionCircle: QuestionCircle,
  person: Person,
  envelope: Envelope,
  leftArrow: LeftArrow,
  close: Close,
  pencil: Pencil,
  outlinedPencil: OutlinedPencil,
  upload: Upload,
  file: FileIcon,
  xCircle: XCircle,
  checkmarkCircle: CheckmarkCircle,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  downloadDocuments: DownloadDocuments,
  bulb: Bulb,
  home: Home,
  exit: Exit,
  outlinedExit: OutlinedExit,
  'file-2': File2,
  comment: Comment,
  download: Download,
  overview: Overview,
  mail: Mail,
  clientStatus: ClientStatus,
  delete: Delete,
  outlinedFiles: OutlinedFiles,
  avatar: Avatar,
  amyHeadshot: AmyHeadshot,
  phone: Phone,
  outlinedFolder: OutlinedFolder,
  'comment-2': Comment2,
  star: Star,
  speaker: Speaker,
  orangeCircleArrow: OrangeCircleArrow,
  flag: Flag,
  exclamationCircle: ExclamationCircle,
  happyPeople: HappyPeople,
  homeSearchImage: HomeSearchImage,
  makeAnOffer: MakeAnOffer,
  calendarWithPeople: CalendarWithPeople,
  moveIn: MoveIn,
  prepareForConditionsImage: PrepareForConditionsImage,
  fulfillConditionsImage: FulfillConditionsImage,
  thankYou: ThankYou,
  inviteClients: InviteClients,
  giftBox: GiftBox,
  filter: FilterImage,
  search: Search,
  outlinedHeart: OutlinedHeart,
  heart: Heart,
  facebook: Facebook,
  'fb-messenger': FbMessenger,
  instagram: Instagram,
  whatsapp: WhatsApp,
  reddit: Reddit,
  share: Share,
};
