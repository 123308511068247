export const RepliersStateToAbbreviatedProvinceMap: Record<string, string> = {
  Ontario: 'on',
  Alberta: 'ab',
  'British Columbia': 'bc',
  Manitoba: 'mb',
  Saskatchewan: 'sk',
  Quebec: 'qc',
  'Nova Scotia': 'ns',
  'Newfoundland and Labrador': 'nl',
  'Newfoundland & Labrador': 'nl',
  'Prince Edward Island': 'pe',
  'Northwest Territories': 'nt',
  'New Brunswick': 'nb',
  Yukon: 'yt',
  Nunavut: 'nu',
};
