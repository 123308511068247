import { ComponentPropsWithoutRef } from 'react';

export type LabelProps = ComponentPropsWithoutRef<'label'>;

export function Label({ className = '', children, ...props }: LabelProps) {
  const styles = 'font-sans text-sm text-grey-2';
  return (
    <label className={`${styles} ${className}`} {...props}>
      {children}
    </label>
  );
}
