import { Environment, isLocal } from './environment';

const environmentBaseUrl = {
  [Environment.Production]: 'https://partner.requityhomes.com',
  [Environment.Staging]: 'https://staging.partner.requityhomes.com',
  [Environment.Development]: 'https://dev.partner.requityhomes.com',
  [Environment.Local]: 'http://localhost:4201',
};

export function getPartnerEnvironmentBaseUrl(environment: string): string {
  if (isLocal(environment)) {
    return environmentBaseUrl[Environment.Local];
  } else {
    return environmentBaseUrl[environment as Environment];
  }
}
