import {
  AdminCreateUserCommand,
  AdminCreateUserCommandInput,
  AdminCreateUserCommandOutput,
  AdminUpdateUserAttributesCommand,
  CognitoIdentityProviderClient,
} from '@aws-sdk/client-cognito-identity-provider';

const client = new CognitoIdentityProviderClient({
  region: process.env['REGION'],
});

interface UserInfo {
  userPoolId: string;
  email: string;
  firstName: string;
  lastName: string;
  occupation: string;
  province: string;
}

// Function to create a new user
export const createCognitoUser = async (userInfo: UserInfo) => {
  const { userPoolId, email, firstName, lastName, occupation, province } =
    userInfo;

  const commandInput: AdminCreateUserCommandInput = {
    UserPoolId: userPoolId,
    Username: email,
    UserAttributes: [
      { Name: 'email', Value: email },
      { Name: 'email_verified', Value: 'true' },
      { Name: 'given_name', Value: firstName },
      { Name: 'family_name', Value: lastName },
      { Name: 'custom:hasOptedInComm', Value: 'yes' },
      { Name: 'custom:occupation', Value: occupation },
      { Name: 'custom:province', Value: province.toUpperCase() },
    ],
    MessageAction: 'SUPPRESS',
  };

  try {
    const response: AdminCreateUserCommandOutput = await client.send(
      new AdminCreateUserCommand(commandInput),
    );
    console.log(`User created: ${JSON.stringify(response, null, 2)}`);

    // Manually confirm the user
    await client.send(
      new AdminUpdateUserAttributesCommand({
        UserPoolId: userPoolId,
        Username: email,
        UserAttributes: [{ Name: 'email_verified', Value: 'true' }],
      }),
    );

    return response;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};
