export interface LogoIconProps {
  color?: ColorOptions;
  className?: string;
  noBackgroundFill?: boolean;
}

export function LogoIcon({
  color = 'navy',
  className = '',
  noBackgroundFill,
}: LogoIconProps) {
  const styles = styleMap[color];
  return (
    <svg
      fill="none"
      viewBox="0 0 100 100"
      className={`inline-block ${className}`}
    >
      <path
        d="M0 5a5 5 0 0 1 5-5h90a5 5 0 0 1 5 5v90a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5Z"
        className={noBackgroundFill ? '' : `${styles.backgroundFill}`}
      />
      <path
        d="M33.224 40.799c0-1.081.472-2.109 1.293-2.813l18.287-15.688a3.706 3.706 0 0 1 4.826 0l18.287 15.688A3.706 3.706 0 0 1 77.21 40.8v23.94a3.706 3.706 0 0 1-3.706 3.705H36.93a3.706 3.706 0 0 1-3.705-3.705v-23.94Z"
        className={`${styles.iconBackgroundFill}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.997 48.381a3.706 3.706 0 0 0-1.293 2.813v23.94a3.706 3.706 0 0 0 3.706 3.705h36.575a3.706 3.706 0 0 0 3.703-3.561l9.14-7.836H66.69V51.194a3.706 3.706 0 0 0-1.292-2.813L47.11 32.693a3.706 3.706 0 0 0-4.826 0L23.997 48.381Z"
        className={`${styles.iconForegroundFill}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.339 23.365a2.3 2.3 0 0 0-2.996 0l-8.372 7.181c.483.202.94.48 1.355.836L65.614 47.07a5.111 5.111 0 0 1 1.783 3.88v16.09h6.731a2.3 2.3 0 0 0 2.301-2.301v-23.94a2.3 2.3 0 0 0-.803-1.746L57.34 23.365Zm7.248 43.674V50.95a2.3 2.3 0 0 0-.803-1.746L45.496 33.515a2.301 2.301 0 0 0-2.86-.108l-6.58 5.646a2.3 2.3 0 0 0-.803 1.746v23.94a2.3 2.3 0 0 0 2.3 2.3h27.034ZM32.442 42.143V64.74a5.11 5.11 0 0 0 5.111 5.11h27.034v5.04a2.3 2.3 0 0 1-2.301 2.301H25.711c-1.27 0-2.3-1.03-2.3-2.3V50.95a2.3 2.3 0 0 1 .802-1.747l8.23-7.06Zm8.43-10.925 11.641-9.986a5.11 5.11 0 0 1 6.656 0L77.456 36.92a5.11 5.11 0 0 1 1.783 3.879v23.94c0 1.741-.871 3.28-2.202 4.202l-11.36 9.773A5.091 5.091 0 0 1 62.285 80H25.711a5.11 5.11 0 0 1-5.11-5.11V50.95a5.11 5.11 0 0 1 1.782-3.88l18.288-15.688c.066-.056.133-.11.2-.164Zm26.525 42.31v-3.679h4.275l-4.275 3.678Z"
        className={`${styles.iconStroke}`}
      />
    </svg>
  );
}

type ColorOptions = 'navy' | 'teal' | 'coral' | 'black';
interface ColorConfig {
  iconStroke: string;
  iconForegroundFill: string;
  iconBackgroundFill: string;
  backgroundFill: string;
}
type StylesConfig = Record<ColorOptions, ColorConfig>;

const styleMap: StylesConfig = {
  navy: {
    iconStroke: 'fill-grey-1',
    iconForegroundFill: 'fill-navy-light',
    iconBackgroundFill: 'fill-navy-med',
    backgroundFill: 'fill-white-pure',
  },
  teal: {
    iconStroke: 'fill-grey-1',
    iconForegroundFill: 'fill-teal-light',
    iconBackgroundFill: 'fill-teal-med',
    backgroundFill: 'fill-white-pure',
  },
  coral: {
    iconStroke: 'fill-grey-1',
    iconForegroundFill: 'fill-coral-light',
    iconBackgroundFill: 'fill-coral-med',
    backgroundFill: 'fill-white-pure',
  },
  black: {
    iconStroke: 'fill-grey-1',
    iconForegroundFill: 'fill-white-pure',
    iconBackgroundFill: 'fill-white-pure',
    backgroundFill: 'fill-white-pure',
  },
};
