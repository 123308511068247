import { ComponentPropsWithRef, forwardRef } from 'react';
import { StateOptions } from '../types';

export interface SelectProps extends ComponentPropsWithRef<'select'> {
  state?: StateOptions;
  placeholder?: string;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { state = 'default', className = '', children, placeholder, ...props },
    ref,
  ) => {
    const styles = `${baseStyles} ${styleMap[state]}`;
    return (
      <select
        ref={ref}
        {...props}
        className={`${styles} ${className}`}
        defaultValue={placeholder && ''}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {children}
      </select>
    );
  },
);

type StylesConfig = Record<StateOptions, string>;

const baseStyles =
  'py-2 pl-4 pr-10 text-base border text-grey-1 disabled:bg-grey-5 disabled:text-grey-3 disabled:cursor-not-allowed';

const styleMap: StylesConfig = {
  default: 'border-grey-4',
  success: 'border-success-dark',
  error: 'border-error-dark',
};
