import { v4 as uuid } from 'uuid';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { UserType } from '@aws-sdk/client-cognito-identity-provider';
import { MAGIC_LINK_TIMEOUT } from '../auth-config/auth-config';

const cisp = new CognitoIdentityServiceProvider();

function getUserAttribute(user: UserType, fieldName: string) {
  return user.Attributes?.find((a) => a.Name === fieldName)?.Value;
}

export const generateMagicLink = async (email: string, baseUrl: string) => {
  try {
    if (!email) {
      throw new Error('Missing email address');
    }

    if (!process.env['USER_POOL_ID']) {
      throw new Error('Missing USER_POOL_ID');
    }

    const { Users } = await cisp
      .listUsers({
        UserPoolId: process.env['USER_POOL_ID']!,
        Filter: `email = "${email}"`,
        Limit: 1,
      })
      .promise();

    console.log(`Found user ${JSON.stringify(Users, null, 2)}`);

    const user = Users?.[0];

    if (!user) {
      console.log('Cognito user not found');
      return null;
    }

    let authChallenge = uuid();
    let timestamp = Date.now();

    const existingChallengeAttribute = getUserAttribute(
      user,
      'custom:authChallenge',
    );

    if (existingChallengeAttribute) {
      const [existingAuthChallenge, existingTimestamp] =
        existingChallengeAttribute.split(',');

      // if the existing challenge is not expired, use it
      if (Date.now() <= Number(existingTimestamp) + MAGIC_LINK_TIMEOUT) {
        authChallenge = existingAuthChallenge;
        timestamp = Number(existingTimestamp);
      }
    }

    await cisp
      .adminUpdateUserAttributes({
        UserPoolId: process.env['USER_POOL_ID']!,
        Username: email,
        UserAttributes: [
          {
            Name: 'custom:authChallenge',
            Value: `${authChallenge},${timestamp}`,
          },
        ],
      })
      .promise();

    const magicLink = `${baseUrl}?email=${email}&code=${authChallenge}`;
    return { magicLink, cognitoUser: user };
  } catch (e) {
    console.error(e);
    return null;
  }
};
