import { ComponentPropsWithRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { StateOptions } from '../types';

export interface CheckboxProps
  extends Omit<ComponentPropsWithRef<'input'>, 'type'> {
  name: string;
  inline?: boolean;
  state?: StateOptions;
}

/** Provides the styling and layout for an input type="checkbox" element and its associated label */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { children, className = '', state = 'default', inline, readOnly, ...props },
    ref,
  ) => {
    const containerStyles = `flex-row items-center gap-4 ${
      inline
        ? `inline-flex ${inlineStyleMap[state]}`
        : `py-2 px-4 border ${styleMap[state]}`
    }`;

    const labelStyles = inline
      ? 'cursor-pointer peer-disabled:cursor-not-allowed w-full'
      : 'grow cursor-pointer peer-disabled:cursor-not-allowed';

    const checkboxStyles =
      'peer w-6 h-6 rounded bg-grey-6 border-2 border-grey-4 cursor-pointer checked:bg-teal-dark checked:hover:bg-teal-dark checked:bg-[length:120%] disabled:cursor-not-allowed';

    return (
      <div className={containerStyles}>
        <label
          className={labelStyles}
          onClick={(event) => event.stopPropagation()}
        >
          <input
            type="checkbox"
            ref={ref}
            className={twMerge(`${checkboxStyles} mr-4 ${className || ''}`)}
            {...props}
          />
          <span
            className={
              inline ? 'text-grey-1' : 'text-grey-3 peer-checked:text-grey-1'
            }
          >
            {children}
          </span>
        </label>
      </div>
    );
  },
);

type StylesConfig = Record<StateOptions, string>;

const styleMap: StylesConfig = {
  default: 'border-grey-4',
  success: 'border-success-dark',
  error: 'border-error-dark',
};

const inlineStyleMap: StylesConfig = {
  default: '',
  success: 'py-2 px-4 border border-success-dark',
  error: 'py-2 px-4 border border-error-dark',
};
