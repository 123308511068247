import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';

const client = new SESClient({ region: process.env['REGION'] });

interface Addresses {
  toAddresses: string[];
  ccAddresses?: string[] | undefined;
  bccAddresses?: string[] | undefined;
}

export const sendEmail = async (
  addresses: Addresses,
  template: string,
  fromAddress: string,
  subject: string,
  note = '',
) => {
  console.log(`Sending ${subject} email to ${addresses.toAddresses}`);

  const Body = note
    ? {
        Html: {
          Charset: 'UTF-8',
          Data: template,
        },
        Text: {
          Charset: 'UTF-8',
          Data: note,
        },
      }
    : {
        Html: {
          Charset: 'UTF-8',
          Data: template,
        },
      };

  const params = {
    Destination: {
      ToAddresses: addresses.toAddresses,
      CcAddresses: addresses.ccAddresses || undefined,
      BccAddresses: addresses.bccAddresses || undefined,
    },
    Message: {
      Body,
      Subject: {
        Charset: 'UTF-8',
        Data: subject,
      },
    },
    Source: fromAddress,
  };

  const command = new SendEmailCommand(params);

  try {
    const data = await client.send(command);
    console.log(`Sent ${subject} email to ${addresses.toAddresses}`, data);
  } catch (error) {
    console.error(
      `Failed to send ${subject} email to ${addresses.toAddresses}`,
      error,
    );
  }
};
