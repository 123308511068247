import { ComponentPropsWithRef, forwardRef } from 'react';
import { StateOptions } from '../types';

export interface InputProps extends ComponentPropsWithRef<'input'> {
  state?: StateOptions;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ state = 'default', type = 'text', className = '', ...props }, ref) => {
    const styles = `${baseStyles} ${styleMap[state]}`;
    return (
      <input
        type={type}
        ref={ref}
        {...props}
        className={`${styles} ${className}`}
      />
    );
  },
);

type StylesConfig = Record<StateOptions, string>;

const baseStyles =
  'py-2 px-4 text-base border text-grey-1 placeholder:text-grey-3 disabled:bg-grey-5 placeholder:disabled:text-grey-3 disabled:cursor-not-allowed';

const styleMap: StylesConfig = {
  default: 'border-grey-4',
  success: 'border-success-dark',
  error: 'border-error-dark',
};
