import { useState } from 'react';

export const useVisibility: (
  defaultVisibility?: boolean,
) => [boolean, (value: boolean) => void] = (defaultVisibility = false) => {
  const [visibility, setVisibility] = useState(defaultVisibility || false);

  const handleVisibility = (value: boolean) => {
    if (value) {
      setVisibility(value);
    } else {
      setVisibility((state) => !state);
    }
  };

  return [visibility, handleVisibility];
};
