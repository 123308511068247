// generate random password that must be 16 characters, including at least one uppercase letter, one lowercase letter, one special symbol and one number
export function randomPassword() {
  const length = 16;
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let password = '';
  const allChars = uppercase + lowercase + numbers + symbols;

  // Add at least one uppercase letter
  password += uppercase.charAt(Math.floor(Math.random() * uppercase.length));

  // Add at least one lowercase letter
  password += lowercase.charAt(Math.floor(Math.random() * lowercase.length));

  // Add at least one special symbol
  password += symbols.charAt(Math.floor(Math.random() * symbols.length));

  // Add at least one number
  password += numbers.charAt(Math.floor(Math.random() * numbers.length));

  // Fill the rest of the password with random characters from allChars
  for (let i = 0; i < length - 4; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // Shuffle the password characters to make it more random
  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
}
