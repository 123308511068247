import { Province } from '@requity-homes/underwriting';

export const provinceMap: Record<Province, string> = {
  ONTARIO: 'Ontario',
  ALBERTA: 'Alberta',
  BRITISH_COLUMBIA: 'British Columbia',
  MANITOBA: 'Manitoba',
  NEW_BRUNSWICK: 'New Brunswick',
  NEWFOUNDLAND_LABRADOR: 'Newfoundland and Labrador',
  NORTHWEST_TERRITORIES: 'Northwest Territories',
  NOVA_SCOTIA: 'Nova Scotia',
  NUNAVUT: 'Nunavut',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  QUEBEC: 'Quebec',
  SASKATCHEWAN: 'Saskatchewan',
  YUKON: 'Yukon',
};
