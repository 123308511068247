import { ComponentPropsWithoutRef } from 'react';

export type ControlGroupProps = ComponentPropsWithoutRef<'div'>;

/** A container for any number of Radio or Checkbox components that are to be grouped
 * together to make the same form input */
export function ControlGroup({
  children,
  className = '',
  ...props
}: ControlGroupProps) {
  return (
    <div className={`flex flex-col gap-4 ${className}`} {...props}>
      {children}
    </div>
  );
}
