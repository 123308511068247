export const convertArrayToObject = (arr: any[]) => {
  if (!Array.isArray(arr)) {
    return {};
  }

  return arr.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
};
