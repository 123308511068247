const otherFileTypes = ['application/zip'];

const documentTypes = [
  'application/msword',
  'application/x-cfb',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.ms-powerpoint',
  'image/svg+xml',
  'application/rtf',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
];

const imageTypes = [
  'image/tiff',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/heic',
];

export const fileUploadAllowedMimeTypes = [
  ...documentTypes,
  ...imageTypes,
  ...otherFileTypes,
];
