import { Environment, isLocal } from './environment';

const environmentBaseUrl = {
  [Environment.Production]: 'https://www.requityhomes.com/listings',
  [Environment.Staging]: 'https://staging.listing.requityhomes.com',
  [Environment.Development]: 'https://dev.listing.requityhomes.com',
  [Environment.Local]: 'http://localhost:4202',
};

export function getListingsEnvironmentBaseUrl(environment: string): string {
  if (isLocal(environment)) {
    return environmentBaseUrl[Environment.Local];
  } else {
    return environmentBaseUrl[environment as Environment];
  }
}
