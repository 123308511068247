import { ReactNode, Children, cloneElement, ReactElement } from 'react';
import { useField } from 'formik';

import { ControlGroup } from '../../control-group/control-group';
import { FormControl } from '../../form-control/form-control';
import { Label } from '../../label/label';
import { Hint } from '../../hint/hint';

export interface FormControlGroupProps {
  label?: string | ReactNode;
  name: string;
  children: ReactElement; // FormRadio or FormCheckbox
}

export function FormControlGroup({
  label,
  name,
  children,
}: FormControlGroupProps) {
  const [, meta] = useField(name);
  return (
    <FormControl>
      {label && <Label>{label}</Label>}
      <ControlGroup>
        {Children.map(children, (child) =>
          cloneElement(child, {
            state: meta.touched && meta.error ? 'error' : undefined,
          }),
        )}
      </ControlGroup>
      {meta.touched && meta.error && <Hint state="error">{meta.error}</Hint>}
    </FormControl>
  );
}
