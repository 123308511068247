import { getEnvironmentBaseUrl } from '../environment/portal-environments';
import { ShortUrlTableDataTypes } from '@requity-homes/data-types';

interface GetShortReferralLinkParams {
  stage: string;
  linkType: string;
  prefix: string;
  shortUrlDynamo: any;
}

export const getShortReferralLink = async (
  params: GetShortReferralLinkParams,
) => {
  const { stage, linkType, prefix, shortUrlDynamo } = params;

  // Query the DB for the prefix
  const prefixRecords = await shortUrlDynamo.query(
    {
      KeyConditionExpression: 'prefix = :prefix and linkType = :linkType',
      ExpressionAttributeValues: {
        ':prefix': prefix,
        ':linkType': linkType,
      },
    },
    'prefix-index',
  );

  let suffix = '';

  if (prefixRecords.Count && prefixRecords.Count > 0) {
    suffix = (prefixRecords.Count + 1).toString();
  }

  const path =
    linkType === ShortUrlTableDataTypes.CUSTOMER_REFERRAL ? '/c/' : '/p/';

  const shortUrl = getEnvironmentBaseUrl(stage) + path + prefix + suffix;

  return shortUrl;
};
