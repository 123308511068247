export const cityToAbbreviatedProvinceMap: Record<string, string> = {
  Edmonton: 'AB',
  'Spruce Grove': 'AB',
  'St. Albert': 'AB',
  'Stony Plain': 'AB',
  'Sherwood Park': 'AB',
  'Red Deer': 'AB',
  Lethbridge: 'AB',
  'Grande Prairie': 'AB',
  Airdrie: 'AB',
  Beaumont: 'AB',
  Leduc: 'AB',
  'North Bay': 'ON',
  'Sault Ste Marie': 'ON',
  'Sault Ste. Marie': 'ON',
  Sudbury: 'ON',
  'Thunder Bay': 'ON',
  Saskatoon: 'SK',
  Martensville: 'SK',
  Warman: 'SK',
  Regina: 'SK',
  'Prince Albert': 'SK',
  'Moose Jaw': 'SK',
};
