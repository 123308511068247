import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useField } from 'formik';
import { Checkbox } from '../../checkbox/checkbox';
import { StateOptions } from '../../types';

export interface FormCheckboxProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'name'> {
  label: string | ReactNode;
  name: string;
  state?: StateOptions;
  inline?: boolean;
}

export function FormCheckbox({ label, ...props }: FormCheckboxProps) {
  const [field] = useField({ type: 'checkbox', ...props });
  return (
    <Checkbox {...field} {...props}>
      {label}
    </Checkbox>
  );
}
