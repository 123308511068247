import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { useVisibility } from '../hooks';
import Link from 'next/link';
import { Logo } from '../logo';
import { Button } from '../buttons';
import { Loading as LoadingSpinner } from '../icons/loading/loading';

const parseURLParams = (url: string) => {
  const qs = url.split('?')[1];
  const qsParams = qs.split('&');
  const qsEmail = qsParams.find((x) => x.startsWith('email='));
  const qsToken = qsParams.find((x) => x.startsWith('code='));
  if (!qsEmail || !qsToken) {
    return { email: '', token: '' };
  }
  const email = decodeURIComponent(qsEmail.substring(6));
  const token = decodeURIComponent(qsToken.substring(5));
  return { email, token };
};

const callVerifyChallengeAPI = async (
  url: string,
  email: string,
  token: string,
) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        challenge: token,
      }),
    });
    const data = await response.json();
    if (response.ok) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        message: data.message,
        isUserNotFound: data.isUserNotFound,
        isEmailInvalid: data.isEmailInvalid,
        isInvalid: data.isInvalid,
        isExpired: data.isExpired,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Unexpected error. Please try again.',
    };
  }
};

let redirectUrl = '';

export function MagicLinkVerifier({
  verificationUrl,
  cookiesRedirectUrl,
}: {
  verificationUrl: string;
  cookiesRedirectUrl: string;
}) {
  redirectUrl = cookiesRedirectUrl;
  let cognitoUser: any;
  const { asPath } = useRouter();
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  const url = `${origin}${asPath}`;
  const [, setSignInStep] = useState('SIGN_IN');
  const [error, setError] = useState<undefined | string>(undefined);
  const [errorBoxVisibility, handleErrorBoxVisibility] = useVisibility();

  async function signIn(email: string) {
    try {
      cognitoUser = await Auth.signIn(email);

      setSignInStep('CUSTOM_CHALLENGE');
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Unexpected error. Please try again.');
      }
    }
  }

  function redirectToMainPage() {
    const { hostname, protocol, port } = window.location;
    window.location.href = `${protocol}//${hostname}${port ? `:${port}` : ''}${
      redirectUrl ?? '/'
    }`;
  }

  function redirectToUserNotFoundPage() {
    const { hostname, protocol, port } = window.location;
    window.location.href = `${protocol}//${hostname}${
      port ? `:${port}` : ''
    }/auth/user-not-found`;
  }

  function redirectLoginLinkExpiredPage() {
    const { hostname, protocol, port } = window.location;
    window.location.href = `${protocol}//${hostname}${
      port ? `:${port}` : ''
    }/auth/sign-in?isExpired=true`;
  }
  function redirectLoginLinkInvalidPage() {
    const { hostname, protocol, port } = window.location;
    window.location.href = `${protocol}//${hostname}${
      port ? `:${port}` : ''
    }/auth/sign-in?isInvalid=true`;
  }

  const authProcess = async (email: string, token: string) => {
    const user = await Auth.currentUserInfo();
    if (user) {
      redirectToMainPage();
    }
    await signIn(email);
    await answerCustomChallenge(token);
  };

  const processURL = async (url: string) => {
    if (!url.includes('code=')) {
      redirectToMainPage();
      return;
    }

    const { email, token } = parseURLParams(url);

    if (token) {
      const {
        success: isValidToken,
        isExpired,
        isInvalid,
        isUserNotFound,
        message,
      } = await callVerifyChallengeAPI(verificationUrl, email, token);

      if (isValidToken) {
        return authProcess(email, token);
      }

      // handle different types of errors
      if (isUserNotFound) {
        redirectToUserNotFoundPage();
        return;
      } else if (isExpired) {
        redirectLoginLinkExpiredPage();
        return;
      } else if (isInvalid) {
        redirectLoginLinkInvalidPage();
        return;
      } else {
        handleErrorBoxVisibility(true);
        setError(message);
        return;
      }
    }
  };

  async function answerCustomChallenge(token: string) {
    try {
      await Auth.sendCustomChallengeAnswer(cognitoUser, token);
      redirectToMainPage();
    } catch (error) {
      console.error('Challenge answer error: ', error);
      setSignInStep('SIGN_IN');
      alert(`The token is invalid.`);
    }
  }

  useEffect(() => {
    // the search string looks like "?email=xxx&token=yyy"
    processURL(url);
  }, []);

  return (
    <div className={`w-full px-6 py-4 text-center md:text-left h-full`}>
      <header className="w-full px-6 py-4 text-center md:text-left absolute">
        <Link href="/">
          <Logo color="coral" className="md:max-h-12 max-h-10 w-40" />
        </Link>
      </header>

      {!errorBoxVisibility && (
        <div className={`flex items-center justify-center text-grey-3 h-full`}>
          <LoadingSpinner className="w-8 h-8 sm:w-10 sm:h-10" />
          <p className={`ml-3`}>Verifying...</p>
        </div>
      )}

      {errorBoxVisibility && (
        <div className="px-8 pt-36 flex flex-col">
          <h1 className="text-2xl font-bold text-center">Login Error</h1>
          <p className="pt-4 text-center">
            {error || 'An unexpected error occurred. Please try again.'}
          </p>

          <NextLink passHref href="/">
            <Button
              color={'black'}
              hierarchy={'secondary'}
              className={'mt-14 text-sm'}
            >
              Back to home page
            </Button>
          </NextLink>
        </div>
      )}
    </div>
  );
}
