import { APIGatewayProxyEvent } from 'aws-lambda';
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import { getCognitoUserAttribute } from '../get-cognito-user-attribute/get-cognito-user-attribute';
import { MAGIC_LINK_TIMEOUT } from '../auth-config/auth-config';

const client = new CognitoIdentityProviderClient({
  region: process.env['REGION'],
});

export const listUsers = async (userPoolId: string, email: string) => {
  const command = new ListUsersCommand({
    UserPoolId: userPoolId,
    Filter: `email = "${email}"`,
    Limit: 1,
  });

  try {
    const { Users } = await client.send(command);
    return Users;
  } catch (error) {
    console.error('Error listing users:', error);
    throw error;
  }
};

export async function handleTokenVerification(
  event: APIGatewayProxyEvent,
  userPoolId: string,
) {
  try {
    const { email, challenge } = JSON.parse(event.body || '{}');
    if (!email) {
      console.log(`Invalid email ${email}`);
      return {
        statusCode: 400,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          message: 'The link is invalid. Please log in again.',
          isEmailInvalid: true,
        }),
      };
    }

    if (!challenge) {
      console.log(`Invalid challenge ${challenge}`);
      return {
        statusCode: 400,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          message: 'The link is invalid. Please log in again.',
          isInvalid: true,
        }),
      };
    }

    const Users = await listUsers(userPoolId, email);

    console.log(`Found user ${JSON.stringify(Users, null, 2)}`);

    const user = Users?.[0];

    if (!user) {
      console.log(`User not found for email ${email}`);
      return {
        statusCode: 400,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          message: 'The link is invalid. Please log in again.',
          isUserNotFound: true,
        }),
      };
    }

    const existingChallengeAttribute = getCognitoUserAttribute(
      user,
      'custom:authChallenge',
    );
    console.log(`Existing challenge attribute ${existingChallengeAttribute}`);
    console.log(`Passed challenge ${challenge}`);

    if (existingChallengeAttribute) {
      const [existingAuthChallenge, existingTimestamp] =
        existingChallengeAttribute.split(',');

      if (existingAuthChallenge !== challenge) {
        console.log(
          `Invalid challenge ${challenge} !== ${existingAuthChallenge}`,
        );

        // we don't delete existing challenge here, because a user might have used the old link

        return {
          statusCode: 400,
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            message: `The link is invalid. Please log in again.`,
            isInvalid: true,
          }),
        };
      }

      // if the existing challenge is not expired, use it
      if (Date.now() > Number(existingTimestamp) + MAGIC_LINK_TIMEOUT) {
        console.log(
          `Challenge expired ${existingTimestamp}: ${
            Date.now() - Number(existingTimestamp)
          } > ${MAGIC_LINK_TIMEOUT}`,
        );

        return {
          statusCode: 400,
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            message: `The link is expired. Please log in again.`,
            isExpired: true,
          }),
        };
      }

      console.log('Challenge is valid');
      return {
        statusCode: 200,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          message: `The token is valid`,
        }),
      };
    }

    return {
      statusCode: 400,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        message: `The link is invalid. Please log in again.`,
      }),
    };
  } catch (e) {
    console.error(e);
    return {
      statusCode: 500,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        message: `Couldn't process the request. Please try after some time.`,
      }),
    };
  }
}
