import { defaultYears } from '../../../config';

export function calculateMonthlySavings(
  homePrice: number,
  minimumDownPayment: number,
  savingsGoalPercent: number,
) {
  if (homePrice && minimumDownPayment) {
    return (
      (homePrice * (1.05 ** defaultYears * (savingsGoalPercent / 100)) -
        minimumDownPayment) /
      (defaultYears * 12)
    );
  }
  return 0;
}
