import { useEffect } from 'react';
import { useRouter } from 'next/router';

export function Redirect({ url }: { url: string }) {
  const router = useRouter();
  useEffect(() => {
    router.push(url);
  }, []);

  return null;
}
