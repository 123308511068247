import { setCookie } from 'cookies-next';

const stage = process.env['NEXT_PUBLIC_STAGE'];

export const setCrossDomainCookie = (key: string, value: string) => {
  if (stage === 'prod' || stage === 'staging' || stage === 'dev') {
    setCookie(key, value, {
      domain: '.requityhomes.com',
      path: '/',
      maxAge: 60 * 60 * 24 * 1, // 1 day
      secure: true, // Required for HTTPS
      sameSite: 'lax', // Ensures the cookie works across subdomains
    });
  } else {
    setCookie(key, value);
  }
};
