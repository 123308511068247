import { useField } from 'formik';
import { twMerge } from 'tailwind-merge';
import { StateOptions } from '../../types';
import { FormRadio } from '../form-radio/form-radio';

export interface FormRadioBooleanProps {
  name: string;
  labels?: [string, string];
  state?: StateOptions;
  radioContainerClass?: string;
  parentClass?: string;
  className?: string;
  onChangeHandler?: (value: boolean) => void;
}

export function FormRadioBoolean({
  name,
  labels,
  state,
  onChangeHandler,
  radioContainerClass,
  ...props
}: FormRadioBooleanProps) {
  const [{ value }, , { setValue }] = useField(name);

  return (
    <div
      className={twMerge(
        `flex flex-col gap-4 md:flex-row ${radioContainerClass || ''}`,
      )}
    >
      <FormRadio
        name={name}
        checked={value === true}
        label={(labels && labels[0]) || 'Yes'}
        onChange={() => {
          setValue(true);
          if (onChangeHandler) {
            onChangeHandler(true);
          }
        }}
        state={state}
        {...props}
      />
      <FormRadio
        name={name}
        checked={value === false}
        label={(labels && labels[1]) || 'No'}
        onChange={() => {
          setValue(false);
          if (onChangeHandler) {
            onChangeHandler(false);
          }
        }}
        state={state}
        {...props}
      />
    </div>
  );
}
