export interface CircleNumberProps {
  className?: string;
  disabled?: boolean;
  children: string;
  status?: 'Unavailable' | 'Complete' | 'Available' | 'Failed';
}

/** Designed for single digit use only */
export function CircleNumber({
  children,
  className,
  disabled,
  status = 'Available',
}: CircleNumberProps) {
  return (
    <span
      className={`rounded-full w-6 h-6 font-bold flex align-middle justify-center
      ${status === 'Complete' ? 'bg-teal-dark text-white-pure' : ''}
      ${status === 'Unavailable' ? 'bg-grey-5 text-grey-3' : ''}
      ${status === 'Available' ? 'bg-teal-light text-grey-3' : ''}
      ${status === 'Failed' ? 'bg-error-light text-white-pure' : ''}
      ${className || ''}`}
    >
      {children}
    </span>
  );
}
