import { enumToArray } from '../types/enum-to-array';

export enum Environment {
  Production = 'prod',
  Staging = 'staging',
  Development = 'dev',
  Local = 'local',
}
export function isLocal(environment: string): boolean {
  const nonLocalEnvironments = enumToArray(Environment);
  return !nonLocalEnvironments.map((i) => i.value).includes(environment);
}

export function isTrackingEnabled(): boolean {
  return process.env['NEXT_PUBLIC_IS_TRACKING_ENABLED'] === 'true';
}
