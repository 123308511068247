export function validatePhoneNumber(
  input: string,
  countryCode?: string,
): boolean {
  if (countryCode && countryCode !== '+1') {
    let internationalNumber = input.replace('+1', '');
    internationalNumber = `${countryCode}${internationalNumber}`;
    const re = /^\+(?:[0-9]?){6,14}[0-9]$/;
    return re.test(internationalNumber);
  }

  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3,4})$/;
  return input.length >= 10 && re.test(input);
}
