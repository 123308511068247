export const cityCoordinates = {
  edmonton: {
    lat: 53.5444,
    lng: -113.4909,
  },
  manitoba: {
    lat: 49.8951,
    lng: -97.1384,
  },
  'north-bay': {
    lat: 46.3091,
    lng: -79.4608,
  },
  regina: {
    lat: 50.4452,
    lng: -104.6189,
  },
  saskatoon: {
    lat: 52.1332,
    lng: -106.67,
  },
  'sault-ste.-marie': {
    lat: 46.521,
    lng: -84.3467,
  },
  'spruce-grove': {
    lat: 53.5412,
    lng: -113.9101,
  },
  sudbury: {
    lat: 46.49,
    lng: -80.99,
  },
  'thunder-bay': {
    lat: 48.3809,
    lng: -89.2477,
  },
  'st.-albert': {
    lat: 53.6539,
    lng: -113.6293,
  },
  'stony-plain': {
    lat: 53.5291,
    lng: -114.0018,
  },
  'sherwood-park': {
    lat: 53.5413,
    lng: -113.2958,
  },
  beaumont: {
    lat: 53.3521,
    lng: -113.4151,
  },
  leduc: {
    lat: 53.2648,
    lng: -113.5525,
  },
  martensville: {
    lat: 52.2931,
    lng: -106.6578,
  },
  warman: {
    lat: 52.3184,
    lng: -106.5751,
  },
  'red-deer': {
    lat: 52.268112,
    lng: -113.811241,
  },
  lethbridge: {
    lat: 49.694168,
    lng: -112.832779,
  },
  'grande-prairie': {
    lat: 55.170834,
    lng: -118.794724,
  },
  airdrie: {
    lat: 51.291668,
    lng: -114.014442,
  },
  'prince-albert': {
    lat: 53.20335,
    lng: -105.753075,
  },
  'moose-jaw': {
    lat: 50.393333,
    lng: -105.551941,
  },
};
