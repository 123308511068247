/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Utility to recursively trim all properties within an object. Returns a new object
 */

import { cloneDeep } from 'lodash';

export function trimAll<T>(value: T): T {
  if (value == null) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.reduce((all, item) => {
      all.push(trimAll(item));
      return all;
    }, []);
  }

  if (typeof value === 'object') {
    const newObject: Record<string, any> = cloneDeep(value);
    Object.entries(newObject).forEach(([key, value]) => {
      newObject[key] = trimAll(value);
    });
    // @ts-ignore
    return newObject;
  }

  if (typeof value === 'string') {
    // @ts-ignore
    return value.trim();
  }

  return value;
}

export function removeTrailingSlash(str: string) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}
