import { useEffect } from 'react';

export const HubSpotTracking = ({ isTrackingEnabled = false }) => {
  useEffect(() => {
    if (isTrackingEnabled) {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/7807202.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      document.body.appendChild(script);
    }
  }, []);

  return null;
};
