export * from './city-coordinates';
export * from './map-denial-reason-to-description';
export * from './utm-source';

export const MARKETING_SITE = 'https://www.requityhomes.com';

export const defaultDownPaymentAmount = 5000;

export const defaultHomePrice = 250000;

export const TWO_PERCENT_DECIMAL = 0.02;

export const FIVE_PERCENT_DECIMAL = 0.05;
