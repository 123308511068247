import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface TextProps {
  children?: ReactNode;
  /** Determines the appropriate font size based on the use case provided */
  useCase?: 'paragraph' | 'large' | 'small' | 'legal';
  /** Determines the font weight */
  fontWeight?: 'normal' | 'bold';
  /** Determines the underlying HTML heading element to render */
  element?: 'p' | 'div' | 'span';
  className?: string;
}

const useCaseStyles = {
  paragraph: 'text-base',
  large: 'text-lg',
  small: 'text-sm',
  legal: 'text-xs',
};

const fontWeightStyles = {
  normal: 'font-normal',
  bold: 'font-bold',
};

export function Text({
  useCase = 'paragraph',
  fontWeight = 'normal',
  element = 'p',
  className,
  children,
  ...props
}: TextProps) {
  const styles = `${useCaseStyles[useCase]} ${fontWeightStyles[fontWeight]}`;
  const Component = element;

  return (
    <Component className={twMerge(`${styles} ${className || ''}`)} {...props}>
      {children}
    </Component>
  );
}
