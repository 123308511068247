// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  calculateMonthlyRent,
  calculateMonthlySavings,
} from '@requity-homes/underwriting';

import {
  defaultDownPaymentAmount,
  defaultHomePrice,
  TWO_PERCENT_DECIMAL,
} from '../const';

export const calculateTotalMonthlyPayment = (
  homePrice: number,
  initialDownPaymentPercentInDecimal: number,
  savingsGoalPercent: number,
) => {
  if (!homePrice) {
    return { rent: 0, savings: 0, totalMonthlyPayment: 0 };
  }

  const initialDownPaymentAmount =
    homePrice * initialDownPaymentPercentInDecimal;
  const initialTwoPercentDownPayment =
    Number(homePrice) < defaultHomePrice
      ? defaultDownPaymentAmount
      : initialDownPaymentAmount;

  const initialFivePercentDownPayment =
    homePrice * initialDownPaymentPercentInDecimal;

  if (initialDownPaymentPercentInDecimal === TWO_PERCENT_DECIMAL) {
    const rent = calculateMonthlyRent(homePrice, initialTwoPercentDownPayment);
    const savings = calculateMonthlySavings(
      homePrice,
      initialTwoPercentDownPayment,
      savingsGoalPercent,
    );

    const totalMonthlyPayment = rent + savings;

    return { rent, savings, totalMonthlyPayment };
  } else {
    const rent = calculateMonthlyRent(homePrice, initialFivePercentDownPayment);
    const savings = calculateMonthlySavings(
      homePrice,
      initialFivePercentDownPayment,
      savingsGoalPercent,
    );

    const totalMonthlyPayment = rent + savings;

    return { rent, savings, totalMonthlyPayment };
  }
};
