import { ComponentPropsWithRef, ElementType, forwardRef, Ref } from 'react';
import { twMerge } from 'tailwind-merge';

interface BaseProps<T extends ElementType> {
  /** Defines the element to render. Defaults to `"button"` */
  as?: T;
  /** Specifies the visual hierarchy to use */
  hierarchy?: HierarchyOptions;
  color?: ColorOptions;
}

export type ButtonProps<T extends ElementType> = BaseProps<T> &
  Omit<ComponentPropsWithRef<T>, keyof BaseProps<T>>;

export const Button = forwardRef(ButtonImplementation);

function ButtonImplementation<T extends ElementType = 'button'>(
  {
    as,
    hierarchy = 'primary',
    color = 'navy',
    children,
    className,
    ...props
  }: ButtonProps<T>,
  ref: Ref<T>,
) {
  const styles = `${baseStyles} ${styleMap[color][hierarchy]}`;
  const Component = as || 'button';
  return (
    // TODO: Fix the type error where the type for Ref doesn't match
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component
      {...props}
      ref={ref}
      className={twMerge(`${styles} ${className || ''}`)}
    >
      {children}
    </Component>
  );
}

type ColorOptions = 'navy' | 'teal' | 'coral' | 'black';
type HierarchyOptions = 'primary' | 'secondary';
type HierarchyConfig = Record<HierarchyOptions, string>;
type StylesConfig = Record<ColorOptions, HierarchyConfig>;

const baseStyles =
  'inline-block mx-auto px-8 py-2 rounded-full font-sans font-bold text-base border cursor-pointer text-center hover:shadow-md-dark disabled:cursor-not-allowed disabled:hover:shadow-none sm:border-2 sm:px-14 sm:py-3';

const styleMap: StylesConfig = {
  navy: {
    primary:
      'bg-navy-dark text-white-pure border-navy-dark active:bg-navy-med disabled:bg-navy-light disabled:border-navy-light',
    secondary:
      'bg-white-pure text-navy-dark border-navy-dark active:bg-navy-light disabled:text-navy-light disabled:border-navy-light',
  },
  teal: {
    primary:
      'bg-teal-dark text-white-pure border-teal-dark active:bg-teal-med disabled:bg-teal-light disabled:border-teal-light',
    secondary:
      'bg-white-pure text-teal-dark border-teal-dark active:bg-teal-light disabled:text-teal-light disabled:border-teal-light',
  },
  coral: {
    primary:
      'bg-coral-dark text-white-pure border-coral-dark active:bg-coral-med disabled:bg-coral-light disabled:border-coral-light',
    secondary:
      'bg-white-pure text-coral-dark border-coral-dark active:bg-coral-light disabled:text-coral-light disabled:border-coral-light',
  },
  black: {
    primary:
      'bg-grey-1 text-white-pure border-grey-1 active:bg-grey-3 disabled:bg-grey-5 disabled:border-grey-5',
    secondary:
      'bg-white-pure text-grey-1 border-grey-1 active:bg-grey-5 disabled:text-grey-5 disabled:border-grey-5',
  },
};
