import { Transition, Dialog } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { Icon } from '../icons';

type Size = 'default' | 'large';

export interface PopupProps {
  title?: string;
  size?: Size;
  children: ReactNode;
  showCloseIcon?: boolean;
  handleClose?: () => void;
}

export function Popup({
  title,
  children,
  size = 'default',
  showCloseIcon = false,
  handleClose,
}: PopupProps) {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => false}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-grey-6 opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white-pure shadow-xl rounded-2xl bg-white ${
                  size === 'large' ? 'md:max-w-3xl' : 'md:max-w-lg'
                }`}
              >
                {(title || showCloseIcon) && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-6"
                  >
                    <div className="flex flex-row justify-between">
                      <span>{title}</span>
                      {showCloseIcon && (
                        <button
                          onClick={() =>
                            typeof handleClose === 'function'
                              ? handleClose()
                              : null
                          }
                        >
                          <Icon glyph={'close'} className="w-4 h-6" />
                        </button>
                      )}
                    </div>
                  </Dialog.Title>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
