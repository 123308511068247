import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useField } from 'formik';
import { FormControl } from '../../form-control/form-control';
import { Label } from '../../label/label';
import { Select } from '../../select/select';
import { Hint } from '../../hint/hint';

export interface FormSelectProps
  extends Omit<ComponentPropsWithoutRef<'select'>, 'id' | 'name'> {
  label?: string | ReactNode;
  name: string;
  // Used when the parent form handles validation messaging separately
  hideErrorHint?: boolean;
}

export function FormSelect({
  label,
  name,
  children,
  hideErrorHint = false,
  ...props
}: FormSelectProps) {
  const [field, meta] = useField(name);
  return (
    <FormControl>
      <Label htmlFor={name}>{label}</Label>
      <Select
        {...field}
        {...props}
        state={meta.touched && meta.error ? 'error' : undefined}
      >
        {children}
      </Select>
      {!hideErrorHint && meta.touched && meta.error && (
        <Hint state="error">{meta.error}</Hint>
      )}
    </FormControl>
  );
}
