import { ReactNode } from 'react';

export interface FieldSetProps {
  title: string;
  children: ReactNode;
}

export function FieldSet({ title, children }: FieldSetProps) {
  return (
    <fieldset className="flex flex-col gap-4" style={{ minInlineSize: 'auto' }}>
      <legend className="text-base font-bold mb-4 text-ellipsis overflow-hidden w-full whitespace-nowrap">
        {title}
      </legend>
      {children}
    </fieldset>
  );
}
