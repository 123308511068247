import axios from 'axios';

const stage = process.env['NEXT_PUBLIC_STAGE'];

const API =
  stage === 'prod'
    ? 'https://api.requityhomes.com/listings'
    : 'https://dev.api.requityhomes.com/listings';

export const toggleFavoriteListings = async (listing: any, idToken: string) => {
  try {
    if (idToken) {
      const data = await axios.post(API, listing, {
        headers: {
          Authorization: `${idToken}`,
        },
      });

      const listingIds = data.data.updatedListings.map(
        (listing: any) => listing.mlsNumber,
      );

      return listingIds;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error.message);
    } else {
      console.log('An unexpected error occurred', JSON.stringify(error));
    }
    return null;
  }
};
