export const convertStringToFloat = (str: string | number = '') => {
  if (typeof str === 'number') {
    return str;
  }

  if (str === null || str === undefined || str === '') {
    return 0;
  }

  return parseFloat(str.replace(/,/g, ''));
};
