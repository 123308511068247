import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { Amplify } from 'aws-amplify';
import LogRocket from 'logrocket';

import { ApolloWrapper } from '../graphql/apollo-client';
import { mouseflowSnippet, segmentSnippet, Track } from '../analytics';
import awsExports from '../aws-exports';
import mixpanel from 'mixpanel-browser';
import './styles.css';
import { isTrackingEnabled, intercomScript } from '@requity-homes/utils';
import { HubSpotTracking } from '@requity-homes/component-library';

Amplify.configure(awsExports);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ApolloWrapper>
      <Head>
        <link rel="icon" href="/favicon-32.png" sizes="32x32" />
        <link rel="icon" href="/favicon-128.png" sizes="128x128" />
        <link rel="icon" href="/favicon-180.png" sizes="180x180" />
        <link rel="icon" href="/favicon-192.png" sizes="192x192" />
      </Head>
      {isTrackingEnabled() && (
        <>
          <Script
            id="segment-script"
            dangerouslySetInnerHTML={{ __html: segmentSnippet() }}
          />
          <Script
            id="mouseflow-script"
            dangerouslySetInnerHTML={{
              __html: mouseflowSnippet(
                process.env.NEXT_PUBLIC_MOUSEFLOW_PROJECT_ID,
              ),
            }}
          />
        </>
      )}
      <Script id="intercom-script">
        {typeof window !== 'undefined' && window.parent === window
          ? intercomScript(process.env.NEXT_PUBLIC_INTERCOM_APP_ID)
          : null}
      </Script>
      {isTrackingEnabled() &&
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)}

      {process.env.NEXT_PUBLIC_LOGROCKET_APP_ID &&
        LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID)}

      <Track></Track>
      <Component {...pageProps} />
      <HubSpotTracking isTrackingEnabled={true} />
    </ApolloWrapper>
  );
}
