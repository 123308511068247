import mixpanel from 'mixpanel-browser';

const isTrackingEnabled = process.env['NEXT_PUBLIC_IS_TRACKING_ENABLED'];
const mixpanelToken = process.env['NEXT_PUBLIC_MIXPANEL_TOKEN'];

export const CommonTrackingEvents = {
  PageView: 'Page view',
};

export async function identifyUser(
  id: string,
  email: string,
  firstName: string,
  lastName: string,
) {
  if (window === undefined || isTrackingEnabled !== 'true') {
    return;
  }

  try {
    mixpanel.identify(id);
    mixpanel.people.set({
      email: email,
      name: `${firstName} ${lastName}`,
    });

    window.dataLayer?.push({
      event: 'userData',
      user_id: id,
    });

    if (window.mouseflow) {
      window.mouseflow.identify(email);
    }
  } catch (error) {
    console.error('Tracking identity error', error);
  }
}

function mixPanelTrackEvent(
  event: string,
  properties?: Record<string, string | undefined>,
) {
  // eslint-disable-next-line no-prototype-builtins
  if (mixpanel.hasOwnProperty('get_distinct_id')) {
    mixpanel.track(event, properties);
  } else {
    mixpanel.init(mixpanelToken!);
    mixpanel.track(event, properties);
  }
}

export function trackEvent(event: string, properties?: Record<string, string>) {
  if (window === undefined || isTrackingEnabled !== 'true') {
    return;
  }

  try {
    if (event.toLowerCase() === CommonTrackingEvents.PageView.toLowerCase()) {
      mixPanelTrackEvent(CommonTrackingEvents.PageView, {
        ...properties,
        path: properties?.['path'],
      });

      return;
    }
    try {
      window.dataLayer?.push({
        event,
        ...properties,
      });
    } catch (error) {
      console.error('GA tracking error', error);
    }

    try {
      mixPanelTrackEvent(event, {
        ...properties,
      });
    } catch (error) {
      console.error('Mixpanel tracking error', error);
    }
  } catch (error) {
    console.error('Tracking error', error);
  }
}

export function resetTracking() {
  if (window === undefined || isTrackingEnabled !== 'true') {
    return;
  }

  try {
    mixpanel.reset();
    window.analytics.reset();
  } catch (e) {
    console.error('Reset tracking error', e);
  }
}
