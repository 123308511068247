import { ComponentPropsWithoutRef } from 'react';
import { StateOptions } from '../types';

export interface HintProps extends ComponentPropsWithoutRef<'div'> {
  state?: StateOptions;
}

/** Text to be displayed beneath a form input to provide more context to the
 * user. This could be instructions, a success message, or an error message */
export function Hint({
  state = 'default',
  className = '',
  children,
}: HintProps) {
  const styles = `${baseStyles} ${styleMap[state]}`;
  return <div className={`${styles} ${className}`}>{children}</div>;
}

type StylesConfig = Record<StateOptions, string>;

const baseStyles = 'font-sans text-xs text-right';

const styleMap: StylesConfig = {
  default: 'text-grey-2',
  success: 'text-success-dark',
  error: 'text-error-dark',
};
