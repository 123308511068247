import { range } from 'lodash';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export interface CircularProgressProps {
  percent: number;
  className?: string;
}

export function CircularProgress({
  percent,
  className,
}: CircularProgressProps) {
  const STROKE_WIDTH = 10;
  const COUNT = 10;
  const turns = 1 / COUNT;
  return (
    <span
      className={`rounded-full w-12 h-12 font-bold flex items-center justify-center text-teal-dark text-xs ${className}`}
    >
      <CircularProgressbarWithChildren
        value={percent}
        text={`${percent}%`}
        strokeWidth={STROKE_WIDTH}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: `rgb(9, 128, 128)`,
          textColor: '#098080',
        })}
      >
        {range(COUNT).map((index) => (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              transform: `rotate(${index * turns}turn)`,
            }}
            key={index}
          >
            <div
              style={{
                background: '#fff',
                width: '2px',
                height: `${STROKE_WIDTH}%`,
              }}
            />
          </div>
        ))}
      </CircularProgressbarWithChildren>
    </span>
  );
}
