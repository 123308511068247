import { twMerge } from 'tailwind-merge';

export function Spinner({ className = '' }) {
  return (
    <div
      className={twMerge(
        'fixed top-0 left-0 z-50 w-screen h-screen bg-white bg-opacity-90 flex items-center justify-center',
        className,
      )}
    >
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-coral-medium"></div>
    </div>
  );
}
