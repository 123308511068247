import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = ComponentPropsWithoutRef<'a'> & NextLinkProps;

export function Link({ href, as, children, className, ...props }: LinkProps) {
  const styles = 'text-teal-dark underline hover:text-teal-med';

  return (
    <NextLink href={href} passHref {...props}>
      <a {...props} className={twMerge(`${styles} ${className || ''}`)}>
        {children}
      </a>
    </NextLink>
  );
}
