import CryptoJS from 'crypto-js';
import { getSSMSecureStringParameter } from '../get-ssm-secure-string-parameter';

export const decryptEmailHash = async (
  encryptedEmail: string,
  parameterName: string,
) => {
  const emailHashSecretKey = await getSSMSecureStringParameter(parameterName);

  const bytes = CryptoJS.AES.decrypt(encryptedEmail, emailHashSecretKey);
  const decryptedEmail = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedEmail;
};

export const encryptEmail = async (email: string, parameterName: string) => {
  const emailHashSecretKey = await getSSMSecureStringParameter(parameterName);
  const encryptedEmail = CryptoJS.AES.encrypt(
    email,
    emailHashSecretKey,
  ).toString();

  return encryptedEmail;
};
