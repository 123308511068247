import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface HeadingProps {
  children: ReactNode;
  /** Specifies the desired heading size */
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  /** Specifies what font face to use */
  fontFace?: 'serif' | 'sans';
  /** Determines the underlying HTML heading element to render */
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
}

const fontStyles = {
  serif: 'font-serif font-bold',
  sans: 'font-sans font-bold',
};

const levelStyles = {
  h1: 'text-3xl md:text-6xl',
  h2: 'text-2xl md:text-5xl',
  h3: 'text-xl md:text-4xl',
  h4: 'text-lg md:text-2xl',
  h5: 'text-md md:text-xl',
};

export function Heading({
  element = 'h1',
  level = 'h1',
  fontFace = 'serif',
  className,
  children,
}: HeadingProps) {
  const styles = `${fontStyles[fontFace]} ${levelStyles[level]}`;
  const Component = element;

  return (
    <Component className={twMerge(`${styles} ${className || ''}`)}>
      {children}
    </Component>
  );
}
