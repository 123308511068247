import { ApolloError } from '@apollo/client';
import { Heading, Text } from '../typography';
import { ReactNode, useEffect, useState } from 'react';
import { Loading as LoadingSpinner } from '../icons';
import { useRouter } from 'next/router';

export interface AsyncHandlerProps {
  loading: boolean;
  error?: ApolloError;
  errorMessage?: string;
  errorStyles?: string;
  children: ReactNode;
}

export function AsyncHandler({
  loading,
  error,
  errorMessage,
  errorStyles,
  children,
}: AsyncHandlerProps) {
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const onHandler = () => {
      try {
        if (isMounted) {
          setIsNavigating(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const offHandler = () => {
      try {
        if (isMounted) {
          setIsNavigating(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    router.events.on('routeChangeStart', onHandler);
    router.events.on('routeChangeComplete', offHandler);
    return () => {
      isMounted = false;
      if (typeof router.events.off === 'function') {
        router.events.off('routeChangeStart', onHandler);
        router.events.off('routeChangeStart', offHandler);
      }
      setIsNavigating(false);
    };
  }, [router.events]);

  if (loading || isNavigating) {
    return (
      <div
        className={`flex items-center justify-center text-grey-3`}
        data-testid="loader"
      >
        <LoadingSpinner className="w-8 h-8 sm:w-10 sm:h-10" />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return (
      <div
        className={`flex flex-col gap-6 justify-center items-center ${errorStyles}`}
      >
        <Heading level="h3" element="h2">
          Oops, something went wrong...
        </Heading>
        <Text useCase="large" className="text-grey-2">
          {errorMessage}
        </Text>
      </div>
    );
  }

  return <>{children}</>;
}
