export const mapDenialReasonToDescription = {
  'High debt-to-income ratio': `The maximum debt-to-income ratio allowed for our program is 50%. When underwriting your application, we take into account all of your existing debt and future rent-to-own monthly payment to make sure the monthly rent-to-own payment is manageable. Since your debt to income ratio is over 50%, we recommend you to pay down your existing debt and reapply at a later date.`,
  'Low approved home budget': `Your current approved budget is lower than the minimum starter home price of your desired city. Given your approved budget, we won’t be able to approve your application as you won’t be able to find any homes within that price range for this specific city. In this case, if you would like to add additional sources of income, include a co-applicant, or are open to considering another city, please include these in your application. Alternatively, reducing your debt-to-income ratio may increase your approved budget and help you qualify in the future.`,
  'Negative bank balances': `When assessing your ability to afford monthly rent-to-own payments, we take into account your past rent payments and monthly expenses to make sure you can afford participating in the program. We review your bank transactions to make sure there are no negative bank balances over the past six months. Generally speaking, negative bank balances mean your expenses are more than your income. We want to make sure you are in a good financial situation before taking on a big commitment like rent-to-own.`,
  'Insufficient down payment': `Depending on your financial situation, the minimum down payment required to start the program can range from 2-10% of the initial home price. We need to see proof that you have the savings available for the down payment. Likewise, if you have been recently discharged from bankruptcy or consumer proposal, we require 10% down payment to start the program with the goal of saving toward a minimum of 20% down payment by the end of year 3. Based on our experience, this will help you maximize your chance of qualifying for a traditional mortgage.`,
  'Missed debt payment': `We noticed there were some missed debt payments over the past six (6) months. We review your debt history to make sure you’re not delinquent with your existing debt over the past six (6) months and are not having any challenges with repayment. We strongly recommend making consistent debt payments when possible and can revisit your application six (6) months from now.`,
  'Credit score less than 500':
    'All applicants need to meet the minimum credit score. Total debt outstanding and recent loan delinquencies will be reviewed.',
  'Unpaid collection or defaulted debt with outstanding balances': `Please note we need our applicants to pay off their collections and/or defaulted debt before applying for the rent-to-own so that you can start rebuilding your credit history and transition from renting to owning in less than three years.`,
};
