import snippet from '@segment/snippet';
import { Auth, Hub } from 'aws-amplify';
import { setUser } from '@sentry/nextjs';
import LogRocket from 'logrocket';
import { isLocal } from '@requity-homes/utils';
import { identifyUser, trackEvent } from '@requity-homes/component-library';
import { CurrentUserInfo } from '../authentication/types';

const stage = process.env.NEXT_PUBLIC_STAGE;

function updateIntercomUserInfo(user: CurrentUserInfo) {
  if (window.Intercom) {
    window.Intercom('update', {
      name: user?.attributes?.given_name,
      email: user?.attributes?.email,
    });
  }
}

Hub.listen('auth', async (data) => {
  // Second condition is added to prevent false error on the server-side
  if (data.payload.event === 'configured' && typeof window !== 'undefined') {
    const user: CurrentUserInfo | null = await Auth.currentUserInfo();

    if (user) {
      await identifyUser(
        user.attributes?.sub,
        user.attributes?.email,
        user.attributes?.given_name,
        user.attributes?.family_name,
      );

      if (!isLocal(stage)) {
        updateIntercomUserInfo(user);
        LogRocket.identify(user.attributes?.email);
        LogRocket.identify(user.attributes?.sub, {
          name: `${user.attributes?.given_name} ${user.attributes?.family_name}`,
          email: user.attributes?.email,
        });
      }
    }
  }

  if (data.payload.event === 'signIn' || data.payload.event === 'signUp') {
    const user: CurrentUserInfo | null = await Auth.currentUserInfo();
    if (user) {
      await identifyUser(
        user.attributes?.sub,
        user.attributes?.email,
        user.attributes?.given_name,
        user.attributes?.family_name,
      );
      trackEvent('login', {
        user_id: user.attributes?.sub,
      });

      if (!isLocal(stage)) {
        setUser({ id: user.attributes.sub, email: user.attributes.email });
        updateIntercomUserInfo(user);
      }
    }
  }
});

export function segmentSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
