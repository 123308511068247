export function mouseflowSnippet(projectId: string) {
  return `
<!-- Mouseflow -->
  window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/${projectId}.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();`;
}
