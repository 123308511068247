import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useField } from 'formik';

import { Radio } from '../../radio/radio';
import { StateOptions } from '../../types';

export interface FormRadioProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'name'> {
  label: string | ReactNode;
  name: string;
  state?: StateOptions;
  parentClass?: string;
}

export function FormRadio({ label, ...props }: FormRadioProps) {
  const [field] = useField({ type: 'radio', ...props });
  return (
    <Radio {...field} {...props}>
      {label}
    </Radio>
  );
}
