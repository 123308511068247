import { ComponentPropsWithRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { StateOptions } from '../types';

export interface RadioProps
  extends Omit<ComponentPropsWithRef<'input'>, 'type'> {
  name: string;
  state?: StateOptions;
  parentClass?: string;
}

/** Provides the styling and layout for an input type="radio" element and
 * its associated label */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    { children, state = 'default', className = '', parentClass = '', ...props },
    ref,
  ) => {
    const radioStyles =
      'peer w-6 h-6 border-2 border-grey-4 cursor-pointer checked:bg-teal-dark checked:hover:bg-teal-dark checked:bg-[length:125%] focus:ring-0';
    return (
      <div
        className={twMerge(
          `flex flex-row items-center py-2 px-4 border md:flex-1 ${
            styleMap[state]
          } bg-white ${parentClass || ''}`,
        )}
      >
        <label className="grow cursor-pointer">
          <input
            type="radio"
            ref={ref}
            className={twMerge(`${radioStyles} mr-4 ${className || ''}`)}
            {...props}
          />
          <span className="text-grey-1">{children}</span>
        </label>
      </div>
    );
  },
);

type StylesConfig = Record<StateOptions, string>;

const styleMap: StylesConfig = {
  default: 'border-grey-4',
  success: 'border-success-dark',
  error: 'border-error-dark',
};
