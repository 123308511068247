export enum Province {
  Alberta = 'ALBERTA',
  BritishColumbia = 'BRITISH_COLUMBIA',
  Manitoba = 'MANITOBA',
  NewfoundlandLabrador = 'NEWFOUNDLAND_LABRADOR',
  NewBrunswick = 'NEW_BRUNSWICK',
  NorthwestTerritories = 'NORTHWEST_TERRITORIES',
  NovaScotia = 'NOVA_SCOTIA',
  Nunavut = 'NUNAVUT',
  Ontario = 'ONTARIO',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  Saskatchewan = 'SASKATCHEWAN',
  Yukon = 'YUKON',
}
