export const cityUtilityLinksMap = {
  Edmonton:
    'https://drive.google.com/file/d/1T1rR_iscSlmD9H9M2VF7OzrO_Pzzd6ab/view',
  Manitoba:
    'https://drive.google.com/file/d/1cs5j24goFmxqQbe2whU_JQChAg5dxFsz/view',
  'North Bay':
    'https://drive.google.com/file/d/1S9ZXUDVnDOKJnFaSn5fYyVJi-VxTncfW/view',
  Regina:
    'https://drive.google.com/file/d/1w_ePHYjYwn6eYpGmuVkPZa4DjVrsBjRb/view',
  Saskatoon:
    'https://drive.google.com/file/d/1zd1InP1p8aEUGBhM-Rv892yosaHvepzQ/view',
  'Sault Ste Marie':
    'https://drive.google.com/file/d/1jN2AiKdixoVhDt-ZT_e8sS_-dKFOnbBR/view',
  'Spruce Grove':
    'https://drive.google.com/file/d/1prurECVZtpcnoPR2iht4lTPjOirP1Dgx/view',
  Sudbury:
    'https://drive.google.com/file/d/1lHR4zPtY2KYXtU-JYvUSE6wG5u9BRvyb/view',
  'Thunder Bay':
    'https://drive.google.com/file/d/1IkU7rwEZlwSjCh90A6wDMOUBQ-E-dcZq/view',
};
