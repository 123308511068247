import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  CommonTrackingEvents,
  trackEvent,
} from '@requity-homes/component-library';

const stage = process.env.NEXT_PUBLIC_STAGE;

let lastTrackedPath;

const track = (path) => {
  // avoid double tracking
  // - in case next router becomes ready and triggers a routeChangeComplete
  if (path === lastTrackedPath) {
    return;
  }
  lastTrackedPath = path;

  trackEvent(CommonTrackingEvents.PageView, {
    path,
  });
};

export function Track() {
  const isBrowser = typeof window !== 'undefined';

  // hacky way to ensure page-tracking is called on initial page load
  const [initialRouteTracked, setInitialRouteTracked] = useState(false);
  if (isBrowser && !initialRouteTracked) {
    track(window.location.pathname);
    setInitialRouteTracked(true);
  }

  // use the setup from the examples directory as normal
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      track(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
}
