import { SSMClient, GetParameterCommand } from '@aws-sdk/client-ssm';

const ssmClient = new SSMClient({ region: 'ca-central-1' });

export const getSSMSecureStringParameter = async (parameterName: string) => {
  const command = new GetParameterCommand({
    Name: parameterName,
    WithDecryption: true,
  });

  const data = await ssmClient.send(command);

  const value = data.Parameter?.Value || '';
  return value;
};
